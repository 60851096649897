<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="
              'https://api.optitmr.com/User_Pictures/' + userData.pictureurl
            "
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullname }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-canSee="'read'"
                :to="{ name: 'UserEdit', params: { id: userData.id } }"
                variant="primary"
              >
                {{ $t("edit") }}
              </b-button>
              <b-button
                v-canSee="'read'"
                :to="{ name: 'users' }"
                variant="outline-danger"
                class="ml-1"
                @click="deleteUser(userData.id)"
              >
                {{ $t("delete") }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="12">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("name") }}</span>
            </th>
            <td class="pb-50">
              {{ userData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("lastName") }}</span>
            </th>
            <td class="pb-50">
              {{ userData.surname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("mobile") }}</span>
            </th>
            <td class="pb-50">
              {{ userData.mobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("isActive") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ $t(userData.active ? "yes" : "no") }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("role") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userRole(userData.usertype) }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon icon="MailIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("email") }}</span>
            </th>
            <td>
              {{ userData.email }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUserList from "../useUserList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { deleteUser } = useUserList();
    var userRole = (roleId) => {
      return roleId == "1"
        ? "Yönetici"
        : roleId == "2"
        ? "Müdür"
        : "Normal Kullanıcı";
    };
    return {
      avatarText,
      deleteUser,
      userRole,
    };
  },
};
</script>
