<template>
  <div><user-view /></div>
</template>
<script>
import UserView from "./UserView/UserView.vue";
export default {
  components: {
    UserView,
  },
  mounted() {},
};
</script>
<style></style>
